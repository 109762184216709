:root {
  --sitecolor: #D9942D;
  --sitecolor10: #fbf4ea;
  --sitecolor15: #f9efe0;
}
:root body.c2 {
  --sitecolor: #6fb54b;
  --sitecolor10: #f1f8ed;
  --sitecolor15: #e9f4e4;
}
:root body.c3 {
  --sitecolor: #00499d;
  --sitecolor10: #e6edf5;
  --sitecolor15: #d9e4f0;
}
:root body.c4 {
  --sitecolor: #00a4d8;
  --sitecolor10: #e6f6fb;
  --sitecolor15: #d9f1f9;
}
:root body.c5 {
  --sitecolor: #87888a;
  --sitecolor10: #f3f3f3;
  --sitecolor15: #ededed;
}
:root body.c6 {
  --sitecolor: #00325e;
  --sitecolor10: #e6ebef;
  --sitecolor15: #d9e0e7;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  line-height: 1.125;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  background-color: #333333;
  border-radius: 20px;
  font-family: 'BitterBold', helvetica, sans-serif;
}
.button:hover,
.button:focus {
  color: #fff !important;
}
.c1 .button:hover,
.c1 .button:focus {
  background-color: #D9942D;
}
.c1 .button:active {
  background-color: #e1a957;
}
.c2 .button:hover,
.c2 .button:focus {
  background-color: #6fb54b;
}
.c2 .button:active {
  background-color: #8cc46f;
}
.c3 .button:hover,
.c3 .button:focus {
  background-color: #00499d;
}
.c3 .button:active {
  background-color: #336db1;
}
.c4 .button:hover,
.c4 .button:focus {
  background-color: #00a4d8;
}
.c4 .button:active {
  background-color: #33b6e0;
}
.c5 .button:hover,
.c5 .button:focus {
  background-color: #87888a;
}
.c5 .button:active {
  background-color: #9fa0a1;
}
.c6 .button:hover,
.c6 .button:focus {
  background-color: #00325e;
}
.c6 .button:active {
  background-color: #335b7e;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}
div.sub1 > .cb-toggle {
  display: none;
}
div.sub1 > .item {
  position: relative;
}
div.sub1 > .item > .cb-toggle {
  display: none;
}
div.sub1 > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
  font-family: 'BitterBold', helvetica, sans-serif;
  text-decoration: none;
  min-width: 68px;
  text-align: center;
  padding-top: 88px;
  padding-bottom: 40px;
  position: relative;
}
div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 68px;
  height: 68px;
  border-radius: 10000px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: #000;
  transition: all 0.4s 0.2s, background 0s;
}
div.sub1 > .item:nth-child(1) > .menu:after {
  background-image: url(/images/icon-strom.svg);
}
div.sub1 > .item:nth-child(2) > .menu:after {
  background-image: url(/images/icon-gas.svg);
}
div.sub1 > .item:nth-child(3) > .menu:after {
  background-image: url(/images/icon-wasser.svg);
}
div.sub1 > .item:nth-child(4) > .menu:after {
  background-image: url(/images/icon-dienstleistungen.svg);
}
div.sub1 > .item:nth-child(5) > .menu:after {
  background-image: url(/images/icon-ueberuns.svg);
}
div.sub1 > .item.c1 > .menu:hover,
div.sub1 > .item.c1 > .menu:focus {
  color: #D9942D;
}
div.sub1 > .item.c1 > .menu:hover:after,
div.sub1 > .item.c1 > .menu:focus:after {
  background-color: #D9942D;
}
div.sub1 > .item.c2 > .menu:hover,
div.sub1 > .item.c2 > .menu:focus {
  color: #6fb54b;
}
div.sub1 > .item.c2 > .menu:hover:after,
div.sub1 > .item.c2 > .menu:focus:after {
  background-color: #6fb54b;
}
div.sub1 > .item.c3 > .menu:hover,
div.sub1 > .item.c3 > .menu:focus {
  color: #00499d;
}
div.sub1 > .item.c3 > .menu:hover:after,
div.sub1 > .item.c3 > .menu:focus:after {
  background-color: #00499d;
}
div.sub1 > .item.c4 > .menu:hover,
div.sub1 > .item.c4 > .menu:focus {
  color: #00a4d8;
}
div.sub1 > .item.c4 > .menu:hover:after,
div.sub1 > .item.c4 > .menu:focus:after {
  background-color: #00a4d8;
}
div.sub1 > .item.c5 > .menu:hover,
div.sub1 > .item.c5 > .menu:focus {
  color: #87888a;
}
div.sub1 > .item.c5 > .menu:hover:after,
div.sub1 > .item.c5 > .menu:focus:after {
  background-color: #87888a;
}
div.sub1 > .item.c6 > .menu:hover,
div.sub1 > .item.c6 > .menu:focus {
  color: #00325e;
}
div.sub1 > .item.c6 > .menu:hover:after,
div.sub1 > .item.c6 > .menu:focus:after {
  background-color: #00325e;
}
.subnavi div.sub1 {
  margin: 0;
}
.subnavi div.sub1 > .item {
  width: 100%;
  display: none;
}
.subnavi div.sub1 > .item.path {
  display: block;
}
.subnavi div.sub1 > .item.path > .menu {
  display: none;
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
  width: 288px;
  margin-top: 1px;
  display: none;
}
div.sub2 > .cb-toggle {
  display: none;
}
div.sub2 > .item {
  width: 100%;
  position: relative;
}
div.sub2 > .item > .cb-toggle {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  cursor: pointer;
  background-size: 10px 6px;
  background-position: right 16px center;
  background-repeat: no-repeat;
}
div.sub2 > .item > .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
  background-position: left 16px center;
}
div.sub2 > .item.item-empty > .cb-toggle {
  display: none;
}
div.sub2 > .item.item-empty > .menu {
  padding-right: 16px;
}
div.sub2 > .item > .menu {
  text-decoration: none;
  padding: 11px 42px 11px 16px;
  font-family: 'BitterLight', helvetica, sans-serif;
}
div.sub2 > .item > .menu.path {
  font-family: 'BitterBold', helvetica, sans-serif;
}
div.sub1 > .item.exit div.sub2 {
  left: unset;
  right: 0;
}
div.sub1 > .item:hover div.sub2 {
  display: block;
}
.subnavi div.sub2 {
  display: block !important;
  position: relative;
  top: 0;
  width: 100%;
  margin-top: 0;
}
.navigation div.sub2 {
  display: none !important;
}
div.sub1 > .item.c1 div.sub2,
body.c1 div.sub2 {
  border-top: 2px solid #D9942D;
  background-color: #fbf4ea;
}
div.sub1 > .item.c1 div.sub2 > .item > .cb-toggle,
body.c1 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c1.svg);
}
div.sub1 > .item.c1 div.sub2 > .item:hover > .menu,
body.c1 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c1 div.sub2 > .item:focus > .menu,
body.c1 div.sub2 > .item:focus > .menu {
  background-color: #f9efe0;
}
div.sub1 > .item.c1 div.sub2 > .item > .menu,
body.c1 div.sub2 > .item > .menu {
  color: #D9942D;
}
div.sub1 > .item.c1 div.sub2 > .item > .menu.path,
body.c1 div.sub2 > .item > .menu.path {
  background-color: #f9efe0;
}
div.sub1 > .item.c2 div.sub2,
body.c2 div.sub2 {
  border-top: 2px solid #6fb54b;
  background-color: #f1f8ed;
}
div.sub1 > .item.c2 div.sub2 > .item > .cb-toggle,
body.c2 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c2.svg);
}
div.sub1 > .item.c2 div.sub2 > .item:hover > .menu,
body.c2 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c2 div.sub2 > .item:focus > .menu,
body.c2 div.sub2 > .item:focus > .menu {
  background-color: #e9f4e4;
}
div.sub1 > .item.c2 div.sub2 > .item > .menu,
body.c2 div.sub2 > .item > .menu {
  color: #6fb54b;
}
div.sub1 > .item.c2 div.sub2 > .item > .menu.path,
body.c2 div.sub2 > .item > .menu.path {
  background-color: #e9f4e4;
}
div.sub1 > .item.c3 div.sub2,
body.c3 div.sub2 {
  border-top: 2px solid #00499d;
  background-color: #e6edf5;
}
div.sub1 > .item.c3 div.sub2 > .item > .cb-toggle,
body.c3 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c3.svg);
}
div.sub1 > .item.c3 div.sub2 > .item:hover > .menu,
body.c3 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c3 div.sub2 > .item:focus > .menu,
body.c3 div.sub2 > .item:focus > .menu {
  background-color: #d9e4f0;
}
div.sub1 > .item.c3 div.sub2 > .item > .menu,
body.c3 div.sub2 > .item > .menu {
  color: #00499d;
}
div.sub1 > .item.c3 div.sub2 > .item > .menu.path,
body.c3 div.sub2 > .item > .menu.path {
  background-color: #d9e4f0;
}
div.sub1 > .item.c4 div.sub2,
body.c4 div.sub2 {
  border-top: 2px solid #00a4d8;
  background-color: #e6f6fb;
}
div.sub1 > .item.c4 div.sub2 > .item > .cb-toggle,
body.c4 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c4.svg);
}
div.sub1 > .item.c4 div.sub2 > .item:hover > .menu,
body.c4 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c4 div.sub2 > .item:focus > .menu,
body.c4 div.sub2 > .item:focus > .menu {
  background-color: #d9f1f9;
}
div.sub1 > .item.c4 div.sub2 > .item > .menu,
body.c4 div.sub2 > .item > .menu {
  color: #00a4d8;
}
div.sub1 > .item.c4 div.sub2 > .item > .menu.path,
body.c4 div.sub2 > .item > .menu.path {
  background-color: #d9f1f9;
}
div.sub1 > .item.c5 div.sub2,
body.c5 div.sub2 {
  border-top: 2px solid #87888a;
  background-color: #f3f3f3;
}
div.sub1 > .item.c5 div.sub2 > .item > .cb-toggle,
body.c5 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c5.svg);
}
div.sub1 > .item.c5 div.sub2 > .item:hover > .menu,
body.c5 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c5 div.sub2 > .item:focus > .menu,
body.c5 div.sub2 > .item:focus > .menu {
  background-color: #ededed;
}
div.sub1 > .item.c5 div.sub2 > .item > .menu,
body.c5 div.sub2 > .item > .menu {
  color: #87888a;
}
div.sub1 > .item.c5 div.sub2 > .item > .menu.path,
body.c5 div.sub2 > .item > .menu.path {
  background-color: #ededed;
}
div.sub1 > .item.c6 div.sub2,
body.c6 div.sub2 {
  border-top: 2px solid #00325e;
  background-color: #e6ebef;
}
div.sub1 > .item.c6 div.sub2 > .item > .cb-toggle,
body.c6 div.sub2 > .item > .cb-toggle {
  background-image: url(/images/arrow-down-c6.svg);
}
div.sub1 > .item.c6 div.sub2 > .item:hover > .menu,
body.c6 div.sub2 > .item:hover > .menu,
div.sub1 > .item.c6 div.sub2 > .item:focus > .menu,
body.c6 div.sub2 > .item:focus > .menu {
  background-color: #d9e0e7;
}
div.sub1 > .item.c6 div.sub2 > .item > .menu,
body.c6 div.sub2 > .item > .menu {
  color: #00325e;
}
div.sub1 > .item.c6 div.sub2 > .item > .menu.path,
body.c6 div.sub2 > .item > .menu.path {
  background-color: #d9e0e7;
}
div.sub3 {
  width: 100%;
  overflow: hidden;
}
div.sub3 > .item {
  width: 100%;
  margin-top: -80px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub3 > .item > .menu {
  text-decoration: none;
  padding: 11px 16px 11px 40px;
  font-family: 'BitterLight', helvetica, sans-serif;
  background-size: auto 10px;
  background-position: 26px 13px;
  background-repeat: no-repeat;
}
div.sub3 > .item > .menu.path {
  font-family: 'BitterBold', helvetica, sans-serif;
}
div.sub3 > .item.c1 > .menu:hover,
div.sub3 > .item.c1 > .menu:focus,
div.sub3 > .item.c1 > .menu.path {
  background-image: url(/images/arrow-right-c1.svg);
}
div.sub3 > .item.c2 > .menu:hover,
div.sub3 > .item.c2 > .menu:focus,
div.sub3 > .item.c2 > .menu.path {
  background-image: url(/images/arrow-right-c2.svg);
}
div.sub3 > .item.c3 > .menu:hover,
div.sub3 > .item.c3 > .menu:focus,
div.sub3 > .item.c3 > .menu.path {
  background-image: url(/images/arrow-right-c3.svg);
}
div.sub3 > .item.c4 > .menu:hover,
div.sub3 > .item.c4 > .menu:focus,
div.sub3 > .item.c4 > .menu.path {
  background-image: url(/images/arrow-right-c4.svg);
}
div.sub3 > .item.c5 > .menu:hover,
div.sub3 > .item.c5 > .menu:focus,
div.sub3 > .item.c5 > .menu.path {
  background-image: url(/images/arrow-right-c5.svg);
}
div.sub3 > .item.c6 > .menu:hover,
div.sub3 > .item.c6 > .menu:focus,
div.sub3 > .item.c6 > .menu.path {
  background-image: url(/images/arrow-right-c6.svg);
}
div.sub2 > .item.cb-toggle-target-active div.sub3 > .item {
  margin-top: 0;
}
div.sub1 > .item.c1 div.sub3,
body.c1 div.sub3 {
  background-color: #f6e4cb;
}
div.sub1 > .item.c1 div.sub3 > .item > .menu,
body.c1 div.sub3 > .item > .menu {
  color: #D9942D;
}
div.sub1 > .item.c2 div.sub3,
body.c2 div.sub3 {
  background-color: #dbedd2;
}
div.sub1 > .item.c2 div.sub3 > .item > .menu,
body.c2 div.sub3 > .item > .menu {
  color: #6fb54b;
}
div.sub1 > .item.c3 div.sub3,
body.c3 div.sub3 {
  background-color: #bfd2e7;
}
div.sub1 > .item.c3 div.sub3 > .item > .menu,
body.c3 div.sub3 > .item > .menu {
  color: #00499d;
}
div.sub1 > .item.c4 div.sub3,
body.c4 div.sub3 {
  background-color: #bfe8f5;
}
div.sub1 > .item.c4 div.sub3 > .item > .menu,
body.c4 div.sub3 > .item > .menu {
  color: #00a4d8;
}
div.sub1 > .item.c5 div.sub3,
body.c5 div.sub3 {
  background-color: #e1e1e2;
}
div.sub1 > .item.c5 div.sub3 > .item > .menu,
body.c5 div.sub3 > .item > .menu {
  color: #87888a;
}
div.sub1 > .item.c6 div.sub3,
body.c6 div.sub3 {
  background-color: #bfccd7;
}
div.sub1 > .item.c6 div.sub3 > .item > .menu,
body.c6 div.sub3 > .item > .menu {
  color: #00325e;
}
.cb-layout2 #head {
  width: 68.14159292%;
  margin-left: 15.92920354%;
}
#home {
  margin: 20px 0 38px;
}
.desk {
  max-width: 88%;
}
.section--multimood {
  margin-top: 140px;
}
.maincontent {
  float: right;
  width: 65.92920354%;
}
.cb-layout2 .maincontent {
  width: 100%;
}
.footarea {
  margin: 0;
}
.footpart {
  width: 25%;
  margin: 40px 0;
}
.energy__unit {
  width: 27.43362832%;
}
#home,
.topservices {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--navigation:after {
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub1 > .item > .menu {
  transition: background-size 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--header.cb-scroll-triggered--active #home {
  margin-top: 14px;
  height: 60px;
  margin-bottom: 14px;
}
.section--header.cb-scroll-triggered--active .topservices {
  margin-top: 20px;
}
.section--navigation.cb-navi-fixed:after {
  width: 100%;
  left: 0;
}
.section--navigation.cb-navi-fixed div.sub1 {
  margin-top: 10px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--navigation.cb-navi-fixed div.sub1 > .item > .menu {
  padding-top: 0;
  padding-bottom: 26px;
  transition: padding 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.section--navigation.cb-navi-fixed div.sub1 > .item > .menu:after {
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.2s;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .cb-album .body,
.area .head,
.area .foot,
.area .part {
  margin-right: 1.62337662%;
  margin-left: 1.62337662%;
  width: 96.75324675%;
}
.area .tiny {
  width: 46.75324675%;
}
.area > .slim {
  width: 50%;
}
.area > .slim .head,
.area > .slim .foot,
.area > .slim .part {
  margin-right: 3.24675325%;
  margin-left: 3.24675325%;
  width: 93.50649351%;
}
.area > .slim.cb-album .body {
  margin-right: 3.24675325%;
  margin-left: 3.24675325%;
  width: 93.50649351%;
}
.area > .slim .tiny {
  width: 93.50649351%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main .cb-album .body,
.cb-layout2 .main .head,
.cb-layout2 .main .foot,
.cb-layout2 .main .part {
  margin-right: 1.57232704%;
  margin-left: 1.57232704%;
  width: 96.85534591%;
}
.cb-layout2 .main .tiny {
  width: 46.85534591%;
}
.cb-layout2 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim .head,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part {
  margin-right: 3.14465409%;
  margin-left: 3.14465409%;
  width: 93.71069182%;
}
.cb-layout2 .main > .slim.cb-album .body {
  margin-right: 3.14465409%;
  margin-left: 3.14465409%;
  width: 93.71069182%;
}
.cb-layout2 .main > .slim .tiny {
  width: 93.71069182%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north .cb-album .body,
.north .head,
.north .foot,
.north .part {
  margin-right: 2.95698925%;
  margin-left: 2.95698925%;
  width: 94.08602151%;
}
.north > .slim {
  width: 33.33333333%;
}
.north > .slim .head,
.north > .slim .foot,
.north > .slim .part {
  margin-right: 8.87096774%;
  margin-left: 8.87096774%;
  width: 82.25806452%;
}
.north > .slim.cb-album .body {
  margin-right: 8.87096774%;
  margin-left: 8.87096774%;
  width: 82.25806452%;
}
.north > .slim .tiny {
  width: 82.25806452%;
}
div.main {
  width: 103.3557047%;
  margin-left: -1.67785235%;
}
.cb-layout2 div.main {
  width: 70.3539823%;
  margin-left: 14.82300885%;
}
div.north {
  width: 106.28571429%;
  margin-left: -3.14285714%;
}
div.areaTen {
  width: 103.3557047%;
  margin-left: -1.67785235%;
}
div.north div.unit.wide {
  width: 33.33333333%;
}
div.north div.unit.wide div.part {
  margin-left: 8.87096774%;
  margin-right: 8.87096774%;
  width: 82.25806452%;
}
div.areaTen div.unit.slim {
  width: 24.9475891%;
}
div.areaTen div.unit.slim div.part {
  margin-left: 6.30252101%;
  margin-right: 6.30252101%;
  width: 87.39495798%;
}
/*# sourceMappingURL=./screen-large.css.map */